var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Popovers")]),
              _c("div", { staticClass: "card-header-actions" }, [
                _c(
                  "a",
                  {
                    staticClass: "card-header-action",
                    attrs: {
                      href: "https://coreui.io/vue/docs/directives/popover",
                      rel: "noreferrer noopener",
                      target: "_blank"
                    }
                  },
                  [_c("small", { staticClass: "text-muted" }, [_vm._v("docs")])]
                )
              ])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c("CCol", { attrs: { col: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "my-3 text-center" },
                      [
                        _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "c-popover",
                                rawName: "v-c-popover",
                                value: {
                                  header: "Popover header",
                                  content: "I am popover content!"
                                },
                                expression:
                                  "{\n                header: 'Popover header',\n                content: 'I am popover content!'\n              }"
                              }
                            ],
                            attrs: { color: "primary" }
                          },
                          [_vm._v(" Click Me ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("CCol", { attrs: { col: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "my-3 text-center" },
                      [
                        _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "c-popover",
                                rawName: "v-c-popover",
                                value: {
                                  header: "Popover!",
                                  content: "I start <strong>open</strong>",
                                  active: true
                                },
                                expression:
                                  "{\n                header: 'Popover!',\n                content: 'I start <strong>open</strong>',\n                active: true\n              }"
                              }
                            ],
                            attrs: { color: "primary" }
                          },
                          [_vm._v(" Click me ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Popovers ")]),
              _c("small", [_vm._v("placement")])
            ],
            1
          ),
          _c(
            "CCardBody",
            { staticClass: "my-3" },
            [
              _c(
                "CRow",
                _vm._l(_vm.placements, function(placement) {
                  return _c(
                    "CCol",
                    {
                      key: placement,
                      staticClass: "py-4 text-center",
                      attrs: { md: "4" }
                    },
                    [
                      _c(
                        "CButton",
                        {
                          directives: [
                            {
                              name: "c-popover",
                              rawName: "v-c-popover",
                              value: {
                                header: "Popover!",
                                content: "Placement " + placement,
                                placement: placement
                              },
                              expression:
                                "{\n              header: 'Popover!',\n              content: `Placement ${placement}`,\n              placement\n            }"
                            }
                          ],
                          attrs: { color: "primary" }
                        },
                        [_vm._v(" " + _vm._s(placement) + " ")]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }